<div id="popup" class="row">
    <div *ngIf="!hasclicked && !hasRequest" class="col-md-12 contentContainer center">
        <div class="row">
            <div id="logo">
                <img src="assets/images/moyee-logo-black-icon.png" class="img-fluid" alt="Moyee logo">
            </div>
        </div>
        
        <div class="row">
            <div id="headtext">
                <span class="text-pink head">{{ 'popup-s1-head' | translate }}</span>
            </div>
        </div>

        <div class="row">
            <div id="undertext">
                <span class="content">{{ 'popup-s1-content' | translate }}</span>
            </div>
        </div>

        <div class="row">
            <div id="inputfield">
                <form [formGroup]="tokenForm" (ngSubmit)="submitToken()">
                    <div class="input-group">
                        <input
                        type="password"
                        class="form-control form-rounded"
                        placeholder="{{ 'popup-s1-input' | translate }}"
                        formControlName="token"
                        >
                        <div class="input-group-prepend">
                            <a (click)="submitToken()"><i class="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </form>
                <span class="text-danger" *ngIf="tokenForm.get('token').invalid && tokenForm.get('token').touched">This field is required.</span>
                <span class="text-danger" *ngIf="wrongToken">Invalid token.</span>
            </div>
        </div>

        <div class="row">
            <div id="switchContiner">
                <span>{{ 'popup-s1-request1' | translate }}</span><br>
                <span class="invite" (click)="askInvite()">{{ 'popup-s1-request2' | translate }}</span>
            </div>
        </div>
    </div>
    
    <div *ngIf="hasclicked && !hasRequest" class="col-md-12 contentContainer center">
        <div class="row">
            <div id="logo">
                <img src="assets/images/moyee-logo-black-icon.png" class="img-fluid" alt="Moyee logo">
            </div>
        </div>
        
        <div class="row">
            <div id="headtext">
                <span class="text-pink head">{{ 'popup-s2-head' | translate }}</span>
            </div>
        </div>

        <div class="row">
            <div id="undertext">
                <span class="content">{{ 'popup-s2-content' | translate }}</span>
            </div>
        </div>

        <div class="row">
            <div id="inputfield">
                <form [formGroup]="mailForm" (ngSubmit)="submitEmail()">
                    <div class="input-group">
                        <input type="email" class="form-control form-rounded" placeholder="{{ 'popup-s2-input' | translate }}" formControlName="email">
                    </div>
                </form>
                <span class="text-danger" *ngIf="mailForm.get('email').invalid && mailForm.get('email').touched">This field is required.</span>
            </div>
        </div>

        <div class="row">
            <div id="inviteContainer">
                <button (click)="submitEmail()" [disabled]="!mailForm.valid" class="btn requestbutton">{{ 'popup-s2-button' | translate }}</button>
            </div>
        </div>
    </div>

    <div *ngIf="hasRequest" class="col-md-12 contentContainer center">
        <div class="row">
            <div id="logo">
                <img src="assets/images/moyee-logo-black-icon.png" class="img-fluid" alt="Moyee logo">
            </div>
        </div>
        
        <div class="row">
            <div id="thankstext">
                <span class="text-pink head">{{ 'popup-s3-head' | translate}}</span>
            </div>
        </div>

        <div class="row">
            <div id="infotext">
                <span>{{ 'popup-s3-content' | translate}}</span>
                <span class="greetings">{{ 'popup-s3-footer' | translate}}</span>
            </div>
        </div>
    </div>
</div>
