import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpService {
  public static readonly URI_API: string = environment.URI_API;
  public static readonly MAIL_API: string = environment.MAIL_API;

  private httpClient: HttpClient;

  public constructor(httpClient: HttpClient) {
    if(!HttpService.URI_API) {
      throw new Error("undefined API link in environment.");
    }
    if(!HttpService.MAIL_API) {
      throw new Error("undefined API link in environment.");
    }
      
      
    this.httpClient = httpClient;
  }

  public get(key: string, data: Object = {}, customHeaders = {}): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders(
      Object.assign({
      }, customHeaders)
    );

    /* Moet beter kunnen. (Object > queryString ) */
    let params: string[] = [];

    for (let key in data)
      params.push(new HttpParams().set(key, data[key]).toString());
    /**/

    // console.log(params);

    return this.getHttpClient().request('GET', `${key}?${params.join('&')}`, {
      headers: headers,
    });
  }

  public post(url: string, data: Object, options: any = {}): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json' 
    });

    const mergedOptions: Object = Object.assign({
      headers: headers,
    }, options);

    return this.getHttpClient().post(url, data, mergedOptions).pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error.message);
  }

  /*
   * Getters & Setters
   */

  public getHttpClient(): HttpClient {
    return this.httpClient;
  }

  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }
}
