<div class="container-fluid" *ngIf="getStatus().isLoading()">
    <div class="row">
        <div id="loaderContainer" class="col-md center">
            <span id="loader">
                <span #ref>
                    <ng-content></ng-content>
                </span> 

                <span *ngIf="ref.children.length == 0">
                    <span id="defaultLoader">
                        <div class="line-spin-fade-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </span>
                </span>
            </span>
        </div>
    </div>
</div>