<div class="loader text-center justify-content-center d-flex justify-content-center" *ngIf="status.getPageStatus() == 'busy'">
  <img src="assets/spinner.gif" class="my-auto" alt="spinner">
</div>

<div class="row" id="mapContainer" *ngIf="status.getPageStatus() != 'locked'">
  <agm-map
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom"
    [disableDefaultUI]="false"
    class="agm-map"
    [mapTypeId]="'satellite'"
    [fullscreenControl]="true"
    [mapTypeControl]="true"
    [streetViewControl]="false"
  >
    <agm-marker-cluster
      imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m"
    >
      <div *ngFor="let m of fetchDataService.getMarkers()">
        <agm-marker
        (markerClick)="clickedMarker(m.FarmerName, m.ID)"
        (markerClick)="closeMarker(infowindow)"
        [latitude]="m.Latitude"
        [longitude]="m.Longitude"
        >
          <agm-info-window #infowindow>
            <div class="container accordion">

              <!-- Tab links -->
              <div class="row tablinks">
                <ul class="nav nav-pills m-auto" id="pills-tab" role="tablist">
                  <li class="nav-item border-left border-dark">
                    <a
                      class="nav-link active"
                      data-toggle="pill"
                      href="#summary"
                      >{{'tab-name-1' | translate}}</a
                    >
                  </li>
                  <li class="nav-item border-left border-right border-dark">
                    <a
                      class="nav-link"
                      data-toggle="pill"
                      href="#farm"
                      >{{'tab-name-2' | translate}}</a
                    >
                  </li>
                  <li class="nav-item border-right border-dark">
                    <a
                      class="nav-link"
                      data-toggle="pill"
                      href="#livefeed"
                      >{{'tab-name-3' | translate}}</a
                    >
                  </li>
                </ul>
              </div>

              <!-- Global info -->
              <div class="row globalinfo">
                <div class="col-md-7">
                  <img
                    src="{{m.Photo}}"
                    class="rounded-circle farmer-img"
                    alt="Cinque Terre"
                  />
                  <span class="farmername">{{m.FarmerName}}</span>
                </div>

                <div class="col-md-5 personinfo">
                  <div class="row info">
                    <div class="col-2 justify-content-center align-self-center">
                      <img src="/assets/images/calendar-alt.png" alt="calendar">
                    </div>
                    <div class="col-10 justify-content-center align-self-center">
                      <span>{{ "info-fm-1" | translate }}</span>
                      <span class="d-block">{{m.formattedDate}}</span>
                    </div>
                  </div>

                  <div class="row info">
                    <div class="col-2 justify-content-center align-self-center">
                      <img src="/assets/images/location-map-2new.png" alt="location">
                    </div>
                    <div class="col-10 justify-content-center align-self-center">
                      <span><a href="#">{{m.City}} {{m.Country}}</a></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Panels -->
              <div class="row panels tab-content">

                <!-- Summaray panel -->
                <div class="tab-pane fade show active col-md-12 summarydata" id="summary">

                  <!-- Row 1  -->
                  <div class="row media info">
                    <div class="col-md-6">
                      <span class="icon plant"></span>
                      <div class="alignment">
                        <span class="text-pink">{{'info-fm-2' | translate}}</span>
                        <span class="d-block">{{m.Product}}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <span class="icon plant"></span>
                      <div class="alignment">
                        <span class="text-pink">{{'info-fm-3' | translate}}</span>
                        <span class="d-block">{{m.Seedlings}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Row 2  -->
                  <div class="row media info">
                    <div class="col-md-6">
                      <span class="icon PlotsTotal"></span>
                      <div class="alignment">
                        <span class="text-pink">{{'info-fm-4' | translate}}</span>
                        <span class="d-block">{{m.PlotsTotal}}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <span class="icon calendar"></span>
                      <div class="alignment">
                        <span class="text-pink">{{'info-fm-5' | translate}}</span>
                        <span class="d-block">{{m.TrainingTotal}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Row 3  -->
                  <div class="row media info">
                    <div class="col-md-6">
                      <span class="icon area"></span>
                      <div class="alignment">
                        <span class="text-pink">{{'info-fm-6' | translate}}</span>
                        <span class="d-block">{{m.FieldSize}}</span>
                      </div>
                    </div>
                    <div class="col-md-6 info">
                      <div class="row">
                        <div class="col-3">
                          <span class="text-pink">{{'info-fm-7' | translate}}</span>
                        </div>
                        <div class="col-9">
                          <span class="d-block" [innerHTML]="m.TrainingNames"></span>
                          <span class="d-block"><a routerLink="/farmertraining">Read more</a></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Row 4  -->
                  <div class="row media info">
                    <div class="col-md-6">
                      <span class="icon familymembers"></span>
                      <div class="alignment">
                        <span class="text-pink">{{'info-fm-8' | translate}}</span>
                        <span class="d-block">{{m.HouseHold}}</span>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- Farm panel -->
                <div class="tab-pane fade col-md-12" id="farm">
                  <div class="row farmerdata border-bottom">
                    <div class="col-md-6">

                        <div id="plothead">
                          <span class="text-pink">{{'info-fm-9' | translate}}</span>
                          <span class="text-pink second">{{'info-fm-10' | translate}}</span>
                        </div>
                        <div id="plots" [innerHTML]="m.PlotData"></div>

                    </div>

                    <div class="col-md-6">
                      <div class="row bottomFarmerImage">
                        <img class="img-fluid bottomImage" src="/assets/images/image5.png" alt="chart">
                      </div>

                      <div class="row text-center">
                        <div class="col-6">
                          <div class="treeImage tree1"></div>
                          <span class="treeAmount">800</span>
                        </div>
                        <div class="col-6">
                          <div class="treeImage tree2"></div>
                          <span class="treeAmount">1600</span>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>

                <!-- Livefeed panel -->
                <div class="tab-pane fade col-md-12" id="livefeed">
                  <div class="row livefeeddata border-bottom">
                    <div class="col-md-6">

                      <table class="table table-borderless">
                        <thead>
                        <tr>
                          <th></th>
                          <th class="text-pink">{{'info-fm-11' | translate}}</th>
                          <th class="text-pink  text-right">{{'info-fm-12' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngIf="m.KG2017">
                          <td class="text-pink">
                            <span class="d-block">2017</span>
                            <span class="d-block small text-dark">{{'info-fm-13' | translate}}</span>
                            <span class="d-block small">{{'info-fm-14' | translate}}</span>
                          </td>
                          <td>{{m.KG2017}}</td>
                          <td>
                            <span class="d-block text-right">{{(m.PP2017/32).toFixed(2)}}</span>
                            <span class="d-block small text-right">{{(m.MP2017/32).toFixed(2)}}</span>
                            <span class="d-block small text-right">{{(m.PP2017/32).toFixed(2)}}</span>
                          </td>
                        </tr>

                        <tr *ngIf="m.KG2018">
                          <td class="text-pink">
                            <span class="d-block">2018</span>
                            <span class="d-block small text-dark">{{'info-fm-13' | translate}}</span>
                            <span class="d-block small">{{'info-fm-14' | translate}}</span>
                          </td>
                          <td>{{m.KG2018}}</td>
                          <td>
                            <span class="d-block text-right">{{(m.PP2018/32).toFixed(2)}}</span>
                            <span class="d-block small text-right">{{(m.MP2018/32).toFixed(2)}}</span>
                            <span class="d-block small text-right">{{(m.PP2018/32).toFixed(2)}}</span>
                          </td>
                        </tr>

                        <tr *ngIf="m.KG2019">
                          <td class="text-pink">
                            <span class="d-block">2019</span>
                            <span class="d-block small text-dark">{{'info-fm-13' | translate}}</span>
                            <span class="d-block small">{{'info-fm-14' | translate}}</span>
                          </td>
                          <td>{{m.KG2019}}</td>
                          <td>
                            <span class="d-block text-right">{{(m.PP2019/32).toFixed(2)}}</span>
                            <span class="d-block small text-right">{{(m.MP2019/32).toFixed(2)}}</span>
                            <span class="d-block small text-right">{{(m.PP2019/32).toFixed(2)}}</span>
                          </td>
                        </tr>

                        </tbody>
                        </table>

                    </div>

                    <div class="col-md-6">
                      <div class="row text-center">
                        <!-- img -->
                        <img class="img-fluid" src="/assets/images/income.png" alt="income">
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </agm-info-window>
        </agm-marker>
      </div>
    </agm-marker-cluster>
  </agm-map>
</div>
