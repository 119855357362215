import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
    private pageStatus: string;

    private loading: boolean;




    public constructor() {
        this.pageStatus = "";
        this.loading = true;
    }

    /*
     * Getters & Setters
     */

    public getPageStatus(): string {
        return this.pageStatus;
    }

    public setPageStatus(pageStatus: string): void {
        this.pageStatus = pageStatus;
    }

    public isLoading(): boolean {
        return this.loading;
    }

    public setLoading(loading: boolean): void {
        this.loading = loading;
    }

}
