import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
;
import { FarmerTrainingComponent } from './farmer-training/farmer-training.component';
import { MapComponentComponent } from './map-component/map-component.component';

const routes: Routes = [
  { path: 'moyee', component: MapComponentComponent },
  { path: '', component: MapComponentComponent },
  { path: 'farmertraining', component: FarmerTrainingComponent },
  { path: ':token', component: MapComponentComponent },
  { path: 'farmertraining/:token', component: FarmerTrainingComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
