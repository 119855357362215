
<div class="container-fluid">
  <div class="row">
    <div class="navbar-text">
      <div class="dropdown">
        <span>
          <a><img class="language-switcher" src="{{ 'header-language-switcher-flag-active' | translate }}" alt="" /></a>
          <i class="dropdown-icon fas fa-angle-down"></i>
        </span>

        <div class="dropdown-content">
          <a class="dropdown-item" (click)="switchLanguage(countryCode.value)">
            <img src="{{'header-language-switcher-flag-not-active' | translate}}" alt="Language selector" />
          </a>
          <input type="hidden" value="{{'header-language-switcher-flag-not-active-code' | translate}}" #countryCode/>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="text-center m-auto">
      <span class="headerimage"></span>
      <span class="title">{{ "header-title" | translate }}</span>
    </div>
  </div>

  <div class="row text-center m-auto">
    <div class="col">
      <div class="row justify-content-center align-self-center">
        <div class="item col-md-3">
          <span class="headericon farmers"></span>
          <span class="number">{{ "farmer-info-1-total" | translate }}</span>
          <span class="number-subtitle">{{ "farmer-info-1" | translate }}</span>
        </div>
  
        <div class="item col-md-3 borderleft">
          <span class="headericon pictureinpicture"></span>
          <span class="number">€ {{ "farmer-info-2-total" | translate }}</span>
          <p class="number-subtitle">{{ "farmer-info-2" | translate }}</p>
        </div>
  
        <div class="item col-md-3 borderleft borderright">
          <span class="headericon plus"></span>
          <span class="number">€ {{ "farmer-info-3-total" | translate }}</span>
          <span class="number-subtitle">{{ "farmer-info-3" | translate }}</span>
        </div>
  
        <div class="item col-md-3">
            <span class="headericon trash"></span>
            <span class="number">{{ "farmer-info-4-total" | translate }}</span>
            <span class="number-subtitle">{{ "farmer-info-4" | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

  <app-popup *ngIf="status.getPageStatus() == 'locked'"></app-popup>
  <app-loader></app-loader>

</div>
