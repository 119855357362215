import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StatusService } from './services/status.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  title = 'FarmerDashboard';
  userLang: string;
  langs: any;
  showPopup: any;

  constructor(public translate: TranslateService, public route: ActivatedRoute, public status: StatusService) {
    this.showPopup = true;

    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('nl');
    this.userLang = navigator.language;
    this.langs = this.userLang.split('-');
    // console.log(this.userLang);

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', this.langs[0]);
      this.userLang = this.langs[0];
    } else {
      this.userLang = localStorage.getItem('lang');
    }

    this.switchLang(this.userLang);

    this.route.params.subscribe((res: any) => {
      // this.show = res.show != 'false';
    });
  }

  log(s) {
    console.log(s);
  }

  ngOnInit() {}

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  switchLanguage(countryCode) {
    this.translate.use(countryCode);
    localStorage.setItem('lang', countryCode);
  }
}
