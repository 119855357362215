<div id="contentContainer" class="row text-center">
    <div class="col-md-12">
        <span class="head">Farmer training sessions</span>
    </div>
    <div class="col-md-6 rightContainer">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title">Training 01-10-2017</span>
                        <span class="card-title">Harvest & Quality 2017</span>
                        <span class="card-text">The training session from October 2017 focused on the harvest of coffee. The training was given in the local village school building and gave farmers insight in the best practices regarding the harvest as well as post-harvest quality. Topics covered during this training where the correct moment of harvesting, when the coffee cherries are perfectly ripe, but also the reason behind this. Explaining to the farmers what the effect would be for them of these correct harvesting techniques, as this will result in a higher volume of high-quality coffee. And therefor making farmers get a higher price for their produce.</span>
                        <span class="card-text">Next to the harvest and quality training this session was also used for the election of the farmer board of representatives, as well as signing the agreements between the farmers and FairChain for the 2017 harvest season.</span>
                    </div>
                    <img src="/assets/images/farmertraining/1_1.jpg" class="card-img-bottom" alt="...">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title">Training 14-04-2018</span>
                        <span class="card-title">Compost</span>
                        <span class="card-text">This training again was a field training, with the focus on the implementation and production of compost for the coffee farming process. The training showed the production, the use and advantages of compost and its application. The first part looked at the way farmers could make their own compost at home. The content of the mix was shown, and a previously produced batch was compared with the raw materials. The finished compost was used in a situation where new coffee trees have been planted. Another part of the training focused on biochar, a waste product from the production of biofuel and a good alternative for compost or other fertilizers.</span>
                        <span class="card-text">With the knowledge from this training farmers can keep the plants they grow in the best possible shape. This because the coffee will need to receive extra nutrients in order to give a consistent high production year after year. Mainly to cope with the natural fluctuation in production a crop like coffee always has to deal with.</span>
                    </div>
                    <img src="/assets/images/farmertraining/3_1.jpg" class="card-img-bottom" alt="...">
                </div>
            </div>
        </div>

    </div>

    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title">Training 18-01-2018</span>
                        <span class="card-title">Renovation & Rejuvenation and Pruning</span>
                        <span class="card-text">This training was given at the beginning of the year, one of the busiest moments for coffee farmers. This is the time of the year many of the farmland management takes place, in order to make sure the coffee trees are in best condition for the crop at the end of the year. Within the Limmu area many farmers must deal with old, poorly managed trees. This results in lower production or in some cases even no harvest at all.</span>
                        <span class="card-text">During the training farmers got instructions on how to take care of their old trees by pruning their trees, what tools to use and what to keep in mind when pruning. Next to this more regular yearly pruning the farmers got a demonstration regarding stumping. This is a harsher method of pruning where a full tree is cut down at about 15 cm from the soil. This will with the correct after care stimulate new shoots from this stem and therefore growing new, young trees.</span>
                        <span class="card-text">With those actions’ farmers learn how to take care for their existing trees as well as how to make sure their land will keep productive over a longer period. However, mainly the stumping is an action that will not show any result until between 3 and 5 years from the actual action. Till then the young tree is not yet producing any fruit, and therefore leaves a farmer with a decrease in income. The effect after this time will in many cases be a much higher yield, both in volume and quality.</span>
                    </div>
                    <img src="/assets/images/farmertraining/2_1.jpg" class="card-img-bottom" alt="...">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title">Training 07-10-2018</span>
                        <span class="card-title">Harvest & Quality 2018</span>
                        <span class="card-text">This yearly harvest training prepared farmers for the 2018 harvest. The focus laid on the harvest of only red, ripe coffee cherries. The farmers learned about the reason why only these cherries should be picked, but also about how they could best do this. This includes picking techniques, where farmers are discouraged to use so called stripping, where the full branch is emptied in one go. Best is to pick selective and focus on the red ripe cherries. This will require the farmers to make multiple rounds over their fields and picking only the cherries ready at that moment.</span>
                        <span class="card-text">Next to that farmers got explanation about the results of not following those tips on the effect of the coffee and why this will result in lower quality coffee that will not be accepted. Also, the steps after harvest where covered, how to keep the cherries in the best state after they where picked from the tree? This includes storage at the farm sight and transport to the washing station.</span>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/5.jpg" class="card-img-bottom" alt="...">
                            
                        </div>
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/4_1.jpg" class="card-img-bottom" alt="...">
                        </div>
                    </div>
                    <div class="card-body">
                        <span class="card-text">The last part of the training was about the processing of coffee at home by sun drying the cherries. Here the farmers learned about building drying beds and how to manage the drying. How to know the coffee is dried sufficiently, when to turn the coffee on the drying bed and when to cover the drying cherries with a cloth against the harsh afternoon sun. </span>
                    </div>
                </div>
            </div>
        </div>
    </div>








    <div class="col-md-12">
        <span class="head">Field research</span>
    </div>
    <div class="col-md-6 rightContainer">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title">Micro loan pilot</span>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/6.jpg" class="card-img-bottom" alt="...">
                        </div>
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/6-1.jpg" class="card-img-bottom" alt="...">
                        </div>
                    </div>
                    <div class="card-body">
                        <span class="card-text">At the end of 2019 and early 2020 a group of master students of the Copenhagen university has visited the Limmu area for their research. This research was a pilot study for the upcoming microloan project. The aim of the visit has been to via focus groups and one on one discussions with farmers gather insight on the perception of loans, risk willingness, trust in Fairchain, preferred pricing models and items for the upcoming Fairchain shop of a selection of farmers. Next to that the user interface (UI) of the possible FairChain app has been tested. </span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title">Training 11-03-2020</span>
                        <span class="card-title">Financial management training</span>
                    </div>
                    <img src="/assets/images/farmertraining/7.jpg" class="card-img-bottom" alt="...">
                    <div class="card-body">
                        <span class="card-text">Next to that the team there has given a training regarding financial literacy and the concept of loans. This training explained what loans are, what the different type of loans are and what that means for a farmer, but also the risk and advantage of loans to the farmers.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>





    <div class="col-md-12">
        <span class="head">Data collection</span>
    </div>
    <div class="col-md-6 rightContainer">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-text">Near the end of 2018 a start has been made on the collection of farmer data using a farm management solution. With this tool a team of data enumerators would visit farmers and collect data on farmers, their household and their coffee land, including the measurement of coffee land size.                        </span>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/8.jpg" class="card-img-bottom" alt="...">
                        </div>
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/8-1.jpg" class="card-img-bottom" alt="...">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-text">In June 2019 the second round of data collection started. This time the software was setup to accommodate for more direct configuration by both the team in the Netherlands as the local data collection team in Ethiopia. A local data manager started as direct contact person in the field, able to do basic setup and training without support from the Netherlands being needed. Collection was organized the same as previously, however changes and updates where made to the way of working as well as the actual data collected. Next to land size measurement also tree counting has been done during this round of collection.</span>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/12.jpg" class="card-img-bottom" alt="...">
                        </div>
                        <div class="col-md-6">
                            <img src="/assets/images/farmertraining/13.jpg" class="card-img-bottom" alt="...">
                        </div>
                    </div>
                    <div class="card-body">
                        <span class="card-text">Next to farm and farmer data Farmforce, the tool used for digital collection, has also been used for the registration of the 2019 harvest. Coffee delivered by farmers was registered using the app. This made it possible to have harvest submissions registered digitally on the spot.</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-text">The collectors all used a mobile phone setup with the app that made it possible for them take surveys directly on their phone, that would later be synced for other users to access the data. The same is true for land size data, where enumerators used the GPS on the phones to plot the fields was directly available once the mobile devices where synced.</span>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <img src="/assets/images/farmertraining/9.jpg" class="card-img-bottom" alt="...">
                        </div>
                        <div class="col-md-4">
                            <img src="/assets/images/farmertraining/10.jpg" class="card-img-bottom" alt="...">
                        </div>
                        <div class="col-md-4">
                            <img src="/assets/images/farmertraining/11.jpg" class="card-img-bottom" alt="...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>