import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { MapComponentComponent } from './map-component/map-component.component';
import { FarmerTrainingComponent } from './farmer-training/farmer-training.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AppRoutingModule } from './app-routing.module';
import { PopupComponent } from './popup/popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpService } from './services/http.service';
import { Loader } from './components/loader/loader';

@NgModule({
  declarations: [
    AppComponent,
    MapComponentComponent,
    FarmerTrainingComponent,
    PopupComponent,
    Loader
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyATkijZXZhnHllZaS8hZkJFcUk6QwgxDuk' // 'AIzaSyCPt4UXpiod-iwTMMa5wyR8dfFADiHclj4'
    }),
    AgmJsMarkerClustererModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [CookieService, HttpService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
