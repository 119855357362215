import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { FetchDataService } from '../fetch-data.service';
import { HttpService } from '../services/http.service';
import { StatusService } from '../services/status.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.less']
})
export class PopupComponent {
  public hasclicked: boolean;
  public hasRequest: boolean;
  public wrongToken: boolean;
  private token = environment.token;
  private cookieAccept: string;
  private http: HttpService;

  public constructor(http: HttpService, public fetch: FetchDataService, public route: ActivatedRoute, public status: StatusService, private cookieService: CookieService) {
    this.http = http;

    if (cookieService.check('acceptCookies')) {
      this.access();
    }
  }

  tokenForm = new FormGroup({
    token: new FormControl('', Validators.required)
  });

  mailForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]))
  });

  private access(): void {
    this.status.setPageStatus("busy");
    this.fetch.setMarkers();
  }

  public askInvite() {
    this.hasclicked = true;
  }

  public askRequest() {
    this.hasRequest = true;
  }

  public submitToken (): void {
    
    if (this.tokenForm.get('token').valid) {
      if (this.tokenForm.value.token == this.token ) {

        this.cookieService.set('acceptCookies', 'true', 365);
        this.setCookieAccept(this.cookieService.get('acceptCookies'));

        this.access();
      }
      this.wrongToken = true;
    }
  }

  public submitEmail () {
    if (this.mailForm.status != "INVALID") {
      this.hasRequest = true;
      this.getHttp().post(`${HttpService.MAIL_API}/sendInviteMail`, {
        "email": this.mailForm.value.email
      }).subscribe((res: any) => {
        // console.log(res);
      });
    }
  }

  /*
  * Getters & setters
  */

  public getCookieAccept(): string {
    return this.cookieAccept;
  }

  public setCookieAccept(cookieAccept: string): void {
      this.cookieAccept = cookieAccept;
  }

  public getHttp(): HttpService {
    return this.http;
  }

  public setHttp(http: HttpService): void {
      this.http = http;
  }
}
