import { Component, OnInit } from '@angular/core';
import {FetchDataService} from "../fetch-data.service";
import { StatusService } from '../services/status.service';

@Component({
  selector: 'app-map-component',
  templateUrl: './map-component.component.html',
  styleUrls: ['./map-component.component.less']
})
export class MapComponentComponent implements OnInit {
  previous;
  zoom: number = 5; // Zoom level on map
  page: number = 0; //  Different pages on popup

  //  Coords for the map (moeten gefixed worden)
  lat: number = 8.149628915643762;
  lng: number = 36.96211071988932;

  totals = {
    farmers: 0,
    premiumPrice: 0,
    marketPrice: 0,
    KGSold: 0,
    livingIncomeDiff: 0,
    cropVariety: 1
  }

  constructor(private fetchDataService: FetchDataService, public status: StatusService, public fetch: FetchDataService) {
    
  }
  ngOnInit(): void {
    this.fetch.setMarkers();
    this.status.setLoading(false);
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  closeMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
}
