import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { FarmerInterface } from './FarmerInterface';
import moment from 'moment';
import { StatusService } from './services/status.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {
  private prodURL = "https://us-central1-provenance-310914.cloudfunctions.net/api/provenance/v1/"
 // private devURL = "http://localhost:5000/provenance-310914/us-central1/api/v1/"
  private usedURL = this.prodURL

  private markers: Array<FarmerInterface>;
  private totals: any;

  private httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjIwMTE0NDUwfQ.SlNj5FQJfYQ6UBRa_hBzytDrsTYd8V62zibp6VHRn_8"
    })
  }


  public loadMap(): void {

  }

  public constructor(private http: HttpClient, public status: StatusService, private router: ActivatedRoute) {
    this.markers = [];

    this.totals = {
      farmers: 0,
      premiumPrice: 0,
      marketPrice: 0,
      KGSold: 0,
      livingIncomeDiff: 0,
      cropVariety: 1
    };

  }

  public setMarkers(): void {
    this.getFarmers().subscribe((markers: any) => {
      Object.keys(markers.data).forEach((key) => {
        markers.data[key].formattedDate = moment(markers.data[key].RegistrationDate).format('L');
        markers.data[key].City = "";
        markers.data[key].Country = "";
        markers.data[key].Product = "";
        let country = this.router.snapshot.queryParamMap.get('country');
        if(country==null)country='';
        console.log("country:"+country);
        if ( markers.data[key].ProducerProducerID.startsWith("EL") && ( country.length==0 || country=='ethiopia') )
        {
          markers.data[key].City = "Limmu,";
          markers.data[key].Country = "Ethiopia";
          markers.data[key].Product = "Coffee: Ethiopian Heirloom Arabica";
          this.markers.push(markers.data[key]);
          this.countTotals(markers.data[key]);
        }

        if ( markers.data[key].ProducerProducerID.startsWith("20210916-") && ( country.length==0 || country=='kenya'))
        {
          var rn: number = ( (Math.floor(Math.random() * (1000 - 1 + 1)) + 1 )/500000);
        //  console.log(rn);

          var lt:number = parseFloat(markers.data[key].Longitude);
       //   console.log(lt);
          lt+=rn;
        //  console.log(lt);

          markers.data[key].Longitude=lt;
          markers.data[key].Country = "Kenya";
          this.markers.push(markers.data[key]);
          this.countTotals(markers.data[key]);
        }



        this.status.setPageStatus("true");
      });
    });
  }

  public getMarkers(): any {
    const hostname = window.location.hostname;
    if (hostname === 'dashboard.fairchain.org' || hostname === 'localhost') {
      return this.markers;
    } else {
      const filteredMarkers = this.markers.filter((marker) => {
        return marker.isMoyeeProd === 'true';
      });

      return filteredMarkers;
    }
    return this.markers;
  }
  private countTotals(currentMarker: any): void {
    //Count farmers
    this.totals.farmers += 1

    //NaN to zero
    const PP2017: any = (parseInt(currentMarker.PP2017) || 0).toFixed(2)
    const PP2018: any = (parseInt(currentMarker.PP2018) || 0).toFixed(2)
    const PP2019: any = (parseInt(currentMarker.PP2019) || 0).toFixed(2)
    const MP2017: any = (parseInt(currentMarker.PP2017) || 0).toFixed(2)
    const MP2018: any = (parseInt(currentMarker.MP2018) || 0).toFixed(2)
    const MP2019: any = (parseInt(currentMarker.MP2019) || 0).toFixed(2)
    const KG2017: any = (parseInt(currentMarker.KG2017) || 0).toFixed(2)
    const KG2018: any = (parseInt(currentMarker.KG2018) || 0).toFixed(2)
    const KG2019: any = (parseInt(currentMarker.KG2019) || 0).toFixed(2)
    //Count premium price
    this.totals.premiumPrice += ( (MP2017/32) + (MP2018/32) + (MP2019/32) | 0);
    this.totals.marketPrice += ( (PP2017/32) + (PP2018/32) + (PP2019/32)| 0);
    this.totals.KGSold += ( (KG2017 + KG2018 + KG2019) | 0) ;
  }

  public getFarmers(): any {
    switch (window.location.pathname) {
      case '/moyee': return this.http.post(this.usedURL + 'dashboard/moyee', '', this.httpOptions);
                     break;
      default: return this.http.post(this.usedURL + 'dashboard/all', '', this.httpOptions);
    }
  }
}
